import request from '../../../utils/API/apiHandler';

export const formatFormQuestionsArrByCategory = questionsArr => {
	let obj = {};
	questionsArr.forEach(doc => {
		const { category = 'uncategorized' } = doc;

		if (!obj[category]) obj[category] = [];

		obj[category].push(doc);
	});
	return obj;
};

export const makeAnswersHash = answers => {
	let obj = {};
	answers?.forEach(val => {
		obj[val.question] = val;
	});
	return obj;
};

export const updateStage = async (ticketId, activeKey, data, action, requestOptions = {}) => {
	const stagesNamesHash = {
		ApplicationTab: 'application',
		DocumentsTab: 'documents',
		RegistryTab: 'registry',
		I9Tab: 'i9',
		'E-VerifyTab': 'everify',
		EMRTab: 'emr',
		OrientationTab: 'orientation',
		ExclusionsTab: 'exclusions',
		'Employment VerificationTab': 'employmentverification',
		'Background CheckTab': 'bgcheck',
		'Pay RateTab': 'payRate',
		'License VerificationTab': 'licVerif',
		'Offer LetterTab': 'offerLetter',
		SignatureTab: 'signature',
		'Face to Face InterviewTab': 'facetofaceinterview',
		'Interview AssessmentTab': 'interviewassessment',
		'Performance DemonstrationsTab': 'performancedemonstrations',
		'Pre-QualificationTab': 'prequalification',
		PreTrainingTab: 'pretraining',
		SchedulingTab: 'scheduling',
		WrittenTestTab: 'writtentest',
	};

	return await request({
		url: `/tickets/ats/${ticketId}/stages/${stagesNamesHash[activeKey]}/${action}`,
		method: 'post',
		data,
		notify: true,
		...requestOptions,
	});
};

export const scanAndUploadFiles = async (ticketId, formData, requestOptions = {}) => {
	return await request({
		url: `/tickets/ats/${ticketId}/everify/scanAndUpload`,
		method: 'post',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		notify: true,
		...requestOptions,
	});
};

export const skipStage = (ticketId, activeKey, wantToComplete) => {
	return request({
		url: `/tickets/ats/${ticketId}/skipStage`,
		method: 'put',
		data: { stage: activeKey, wantToComplete },
	});
};

export const getStage = (stageName, ticket) => {
	return ticket.stages.find(stage => stage.name === stageName);
};

// Message comes in format : `${stage} action failed \n ${error}
export const getStageFromSocketMessage = message => {
	return message ? message.split(' ')[0] : null;
};

export function removeDuplicates(arr) {
	return arr.filter((value, index, self) => {
		return self.indexOf(value) === index;
	});
}

export const caseClosureReasons = {
	INVALID_QUERY: 'Invalid Query',
	EMPLOYMENT_NOT_AUTHORIZED: 'Resolved Unauthorized / Terminated',
	SELF_TERMINATED: 'Self-Terminated',
	EMPLOYEE_NOT_TERMINATED: 'Employee Not Terminated',
	FORCED_CLOSURE: 'Forced Closure',
	REVIEWED: 'Reviewed',
	OPERATOR_ERROR: 'Operator Error',
	EMPLOYMENT_AUTHORIZED: 'Resolved Authorized',
	DUPLICATE_CASE_ENTERED_BY_EMPLOYER: 'The case is a duplicate because the employer created a case with the same data within the past 30 days.',
	EXPIRED_DOCUMENT: 'The case is being closed because the document is expired.',
	EMPLOYER_INSTRUCTED_TO_CREATE_NEW_CASE: 'The case is being closed because DHS or SSA instructed this employer to create a new case for this employee.',
	TECHNICAL_ISSUES_WITH_EVERIFY: 'This case is being closed because of technical issues with E-Verify.',
	EMPLOYEE_CONTINUES_TO_WORK_AFTER_EA: 'The employee continues to work for the employer after receiving an Employment Authorized result.',
	EMPLOYEE_CONTINUES_TO_WORK_AFTER_FNC: 'The employee continues to work for the employer after receiving a Final Nonconfirmation result.',
	EMPLOYEE_CONTINUES_TO_WORK_AFTER_NO_SHOW: 'The employee continues to work for the employer after receiving a No Show result.',
	EMPLOYEE_CONTINUES_TO_WORK_AFTER_UNCONTESTED_TNC:
		'The employee continues to work for the employer after choosing not to contest a Tentative Nonconfirmation.',
	EMPLOYEE_TERMINATED_FOR_FNC_RESULT: 'The employee was terminated by the employer for receiving a Final Nonconfirmation result.',
	EMPLOYEE_QUIT: 'The employee quit working for the employer.',
	EMPLOYEE_TERMINATED_FOR_OTHER_REASONS: 'The employee was terminated by the employer for reasons other than E-Verify.',
	EMPLOYEE_TERMINATED_FOR_NO_SHOW: 'The employee was terminated by the employer for receiving a No Show result.',
	EMPLOYEE_TERMINATED_FOR_UNCONTESTED_TNC: 'The employee was terminated by the employer for choosing not to contest a Tentative Nonconfirmation.',
	DUPLICATE_CASE_DATA_EXISTS: 'The case is being closed because another case with the same data already exists.',
	INCORRECT_DATA: 'The case is being closed because the data entered is incorrect.',
	EMPLOYER_INSTRUCTED_TO_CLOSE_BY_DHS: 'The case is being closed because DHS instructed this employer to close the case.',
	EMPLOYER_INSTRUCTED_TO_CLOSE_BY_SSA: 'The case is being closed because SSA instructed this employer to close the case.',
	OTHER: 'The case received a Final Nonconfirmation and is being closed with a reason of "Other"',
	ADMINISTRATIVELY_CLOSED: 'FNC – Administratively closed by DHS',
	INCOMPLETE_ADMIN_CLOSED: 'Incomplete Case – Administratively closed by DHS',
	EA_AUTO_CLOSE: 'Employment Authorized Auto Close',
	EMPLOYER_IS_NO_LONGER_CLIENT: 'The employer is no longer our client.',
	EMPLOYER_INSTRUCTED_TO_CLOSE: 'The case is being closed because DHS or SSA instructed this employer to close the case.',
	NO_LONGER_EMPLOYED: 'The employee is no longer employed by the employer for reasons unrelated to E-Verify and the TNC process could not be completed.',
};

export const currentCaseResults = {
	NOT_AUTHORIZED_EMPLOYMENT: 'Not Authorized for Employment',
	EMPLOYMENT_AUTHORIZED: 'Employment Authorized',
	NO_SHOW: 'No Show',
};

export const statusMessages = {
	DRAFT: 'The case has been created and not yet submitted.',
	UNCONFIRMED_DATA: 'The case has data that needs to be confirmed with a confirm details call.',
	PHOTO_MATCH: 'The case requires a photo match.',
	SCAN_AND_UPLOAD: 'The case requires photos to be uploaded.',
	MANUAL_REVIEW: 'The case is being reviewed by DHS.',
	PENDING_REFERRAL: 'The case has received a TNC, but that TNC has not been referred.',
	REFERRED: 'The case has been referred.',
	QUEUED: 'The case is being processed by external systems.',
	EMPLOYMENT_AUTHORIZED: 'The case has received employment authorized and can now be closed.',
	FINAL_NONCONFIRMATION: 'The case has received a final non-confirmation and must be closed with the appropriate reason.',
	CLOSE_CASE_AND_RESUBMIT: 'The case has encountered technical errors, or a separate system has determined the case must be resubmitted.',
	CLOSED: 'The case has been closed.',
	CONTINUANCE: 'The case has been referred and placed into continuance by the external system.',
	RESOLVED: 'The case had a TNC, but it has received a final resolution from an external system.',
	NOT_REFERRED: 'The case never had a TNC of this type.',
};

export function getFileNamesToRestoreOrDelete(filesToRestoreOrDelete) {
	const fileNames = filesToRestoreOrDelete
		.flatMap(file => {
			return [file.name ?? null, file.hoverPageName ?? null, file.imageName ?? null];
		})
		.filter(fileToRestore => fileToRestore);
	return fileNames;
}
