import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import { Avatar, Menu, Space, Switch } from 'antd';
import { LogoutOutlined, SettingFilled, UserOutlined } from '@ant-design/icons';
import { enable as enableDarkMode, disable as disableDarkMode } from 'darkreader';

import { logout } from '../../pages/Login/authSlice';
import { clearTickets } from '../../pages/Department/TicketsList/ticketListSlice';
import { socket } from '../../utils/socketHandler';
import request from '../../utils/API/apiHandler';
import ProgressNotification from '../../shared/components/ProgressNotification/ProgressNotification';
import CopilotActions from '../../features/chat/copilotActions';
import { Moon } from '../../assets/images/icons/Moon';
import { Sun } from '../../assets/images/icons/Sun';

import './mainNav.less';

const orderOfDepartments = {
	ATS: 0,
	Coordination: 1,
	Recruitment: 2,
	HHAU: 3,
	Payroll: 4,
	Intake: 5,
	Compliance: 6,
	'Value Based': 7,
	Caregiver: 8,
	Patient: 9,
};

const isProduction = process.env.REACT_APP_ENV === 'production';

const MainNav = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const [showLogoutMenu, setShowLogoutMenu] = useState(false);
	const [showSettingsMenu, setShowSettingsMenu] = useState(false);
	const [departments, setDepartments] = useState(null);
	const [managementLinks, setManagementLinks] = useState([]);
	const [darkMode, setDarkMode] = useState(false);
	const {
		appStatus: { metaInfo: AppMetaInfo },
		auth: { user: authUser },
	} = useSelector(state => state);

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem('user'));
		if (!user && !location?.pathname?.startsWith('/error')) {
			return handleLogout();
		} else if (user?.departments) setDepartments([...user?.departments]);
		const theme = localStorage.getItem('theme');
		if (theme === 'dark') {
			activateDarkMode();
			setDarkMode(true);
		} else {
			disableDarkMode();
			setDarkMode(false);
		}
	}, []);

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem('user'));
		if (!user) {
			return;
		}
		request({
			url: `management/meta/links`,
		}).then(res => {
			setManagementLinks(res);
		});
	}, []);

	const formattedDepartments =
		departments &&
		departments
			.sort((a, b) => {
				return orderOfDepartments[a] - orderOfDepartments[b];
			})
			.map(item => ({
				key: item.split(' ').join('-').toLowerCase(),
				name: item === 'caregiver' || item === 'patient' ? `${item}s` : item,
			}));

	let { departmentName } = useParams();

	function handleLogout() {
		dispatch(logout());
	}

	const onClickMenuItem = ({ key }) => {
		history.push(key);
		setShowSettingsMenu(false);
	};

	useEffect(() => {
		socket.emit('rooms:change', [{ name: location.pathname.slice(1), subscribe: true }]);
	}, []);

	const userActionsMenu = (
		<Menu
			onClick={handleLogout}
			items={[
				{
					label: (
						<span>
							<LogoutOutlined style={{ marginRight: '10px' }} />
							Logout
						</span>
					),
				},
			]}
		/>
	);

	const settingsActionsMenu = (
		<Menu
			onClick={onClickMenuItem}
			items={[
				{
					key: '/user-management',
					label: <span>User Management</span>,
				},
				{
					key: '/dashboard',
					label: <span>Dashboard</span>,
				},
				...(managementLinks
					? managementLinks?.map(link => ({
							key: `/management/${link?.path}`,
							label: <span>{link?.label}</span>,
					  }))
					: []),
			]}
		/>
	);

	return (
		<div className="nav">
			<div className="nav__logo"></div>
			<div className="nav__menu">
				<Menu
					theme="dark"
					className="isoDashboardMenu"
					mode="horizontal"
					items={[
						{
							key: '/',
							label: (
								<Link data-text={'Home'} to="/">
									Home
								</Link>
							),
						},
						...(formattedDepartments
							? formattedDepartments?.map(item => ({
									key: item.key,
									label: (
										<Link
											data-text={item.name}
											onClick={() => {
												socket.emit('rooms:change', [{ name: item.key.toLowerCase(), subscribe: true }]);
												!(item.key.toLowerCase() === departmentName) && dispatch(clearTickets());
											}}
											style={{ textTransform: 'capitalize', color: '#fff' }}
											to={{
												pathname: `/${item.key}`,
												state: { from: location.pathname },
											}}
										>
											{item.name}
										</Link>
									),
							  }))
							: []),
						...(!isProduction
							? [
									{
										key: '/sms-test-chat',
										label: (
											<Link to="/sms-test-chat" style={{ color: '#fff' }}>
												SMS Test Chat
											</Link>
										),
									},
							  ]
							: []),
						...(!AppMetaInfo?.chat || authUser?.avoidChat
							? []
							: [
									{
										key: '/chat',
										label: (
											<Link data-text={'Chat'} to="/chat" style={{ color: '#fff' }}>
												Chat
											</Link>
										),
									},
							  ]),
					]}
					selectedKeys={[departmentName || location.pathname]}
				/>
			</div>
			<div className="nav__user-dropdown">
				<Space direction="horizontal">
					<div style={{ color: '#fff', fontSize: '12px' }}>New! Theme selector</div>
					<Switch
						checked={darkMode}
						className="dark-mode-switch"
						checkedChildren={<Moon />}
						unCheckedChildren={<Sun />}
						onChange={handleChangeDarkMode}
					></Switch>
				</Space>
				<CopilotActions />
				<ProgressNotification />
				<Link className="support-link" to={{ pathname: 'https://application.arla.ai/222634366883060' }} target="_blank">
					Support
				</Link>
				<a
					className="ant-dropdown-link"
					onClick={() => {
						setShowSettingsMenu(!showSettingsMenu);
						setShowLogoutMenu(false);
					}}
				>
					<SettingFilled className="settings-icon" />
				</a>
				<a
					className="ant-dropdown-link"
					onClick={() => {
						setShowLogoutMenu(!showLogoutMenu);
						setShowSettingsMenu(false);
					}}
				>
					<Avatar className="bg-gray" icon={<UserOutlined style={{ verticalAlign: 'text-top' }} />} />
				</a>
			</div>
			{showLogoutMenu && <div className="nav__user-dropdown__menu">{userActionsMenu}</div>}
			{showSettingsMenu && <div className="nav__user-dropdown__menu">{settingsActionsMenu}</div>}
		</div>
	);

	function handleChangeDarkMode(isDarkMode) {
		if (isDarkMode) {
			localStorage.setItem('theme', 'dark');
			setDarkMode(true);
			activateDarkMode();
		} else {
			localStorage.setItem('theme', 'light');
			setDarkMode(false);
			disableDarkMode();
		}
	}

	function activateDarkMode() {
		enableDarkMode({
			brightness: 100,
			contrast: 90,
			sepia: 10,
		});
	}
};
export default MainNav;
